import React, { useState, useEffect } from 'react';
import { fetch, getPostsUrl, createSlug, API_PATH } from '../utils/dao';
import { truncate } from '../utils/truncate';

// Components
import {
  Layout,
  Container,
  NewsCard,
  Card,
  CardGroup,
  Button,
} from '../components';

// Data
import data from '../data/pages/news.json';

const News = () => {
  const [response, setResponse] = useState({});
  const [posts, setPosts] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [params, setParams] = useState({
    _limit: 4,
    _start: 0,
    _sort: 'published_at:desc',
  });

  const getPosts = (next) => {
    fetch(getPostsUrl(params, next)).then((data) => {
      setResponse(data);
    });
  };

  useEffect(() => {
    getPosts();
  }, []);

  useEffect(() => {
    if (response && response.length > 0) {
      setPosts([...posts, ...response]);
      setParams({ ...params, _start: params._start + params._limit });
      setLoadMore(response.length === params._limit);
    } else {
      setLoadMore(false);
    }
  }, [response]);

  return (
    <Layout pageContext={data}>
      <Container>
        {/* Newest Post (Featured post) */}
        {posts.map((post, i) => {
          if (i === 0)
            return (
              <NewsCard
                id={post.id}
                key={`${post.id}-${i}`}
                imagePath={`${API_PATH}${post.image.formats.small.url}`}
                title={post.title}
                text={`${
                  post.description ? truncate(post.description, 45) : ''
                }...`}
                date={post.published_at}
                cta={{
                  src: `/post/${post.id}/${createSlug(post.title)}`,
                  label: 'Continue Reading',
                }}
              />
            );
        })}

        {/* Cards - All posts other than first post */}
        <CardGroup>
          {posts.slice(1).map((post, i) => (
            <Card
              id={post.id}
              key={`${post.id}-${i}`}
              imagePath={`${API_PATH}${post.image.formats.small.url}`}
              title={post.title}
              date={post.published_at}
              styleType="news"
              cta={{
                src: `/post/${post.id}/${createSlug(post.title)}`,
              }}
            />
          ))}
        </CardGroup>

        {loadMore && (
          <div style={{ textAlign: 'center' }}>
            <Button
              type="button"
              gutter={{ left: 'auto', right: 'auto', top: '1.5rem' }}
              onClick={() => {
                getPosts();
              }}>
              Load more news posts
            </Button>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default News;
